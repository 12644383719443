<template>
  <div>
    <button
      class="vca-button"
      style="width: auto"
      @click.prevent="downloadZip()"
    >
      {{ $t("button.download") }}
    </button>
    <div style="display: flex; gap: 1em">
      <div v-for="(res, index) in value" :key="res.id" style="width: 220px">
        <img
          style="border: 5px solid #555; border-color: #008fc3"
          :src="receiptURL(res.id)"
          width="210"
          height="297"
        />
        <vca-row>
          <div class="vca-center">
            <button
              class="vca-button"
              style="margin: 0; padding: 0"
              @click.prevent="download(res.id, index)"
            >
              {{ $t("button.download_icon") }}
            </button>
          </div>
          <div class="vca-center" v-if="displayDelete">
            <vca-cancel-button
              @click="deleteReceipt(res.id)"
              :placeholder="$t('button.delete')"
            />
          </div>
        </vca-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReceiptDisplay",
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    displayDelete: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "quittung",
    },
    id: {
      type: String,
      default: "",
    },
  },
  methods: {
    receiptURL(value) {
      return process.env.VUE_APP_BACKEND_URL + "/finances/receipt/" + value;
    },
    receiptURLZip() {
      return (
        process.env.VUE_APP_BACKEND_URL + "/finances/receipt/zip/" + this.id
      );
    },
    async download(value, index) {
      const image = await fetch(this.receiptURL(value), {
        credentials: "include",
      });
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const link = document.createElement("a");
      link.href = imageURL;
      let number = index + 1;
      link.download = this.name + "_" + number;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadZip() {
      const image = await fetch(this.receiptURLZip(), {
        credentials: "include",
      });
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const link = document.createElement("a");
      link.href = imageURL;
      link.download = this.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    deleteReceipt(value) {
      this.$store
        .dispatch({
          type: "deposits/deleteReceipt",
          data: value,
        })
        .then(() => {
          this.$emit("updated");
        });
    },
  },
};
</script>
