<template>
  <div>
    <p style="font-weight: bold">{{ $t("deposit.external.info.text") }}</p>
    <br />

    <p>{{ $t("deposit.external.info.headline") }}</p>
    <p>{{ $t("deposit.external.info.to") }}</p>
    <p>{{ $t("deposit.external.info.iban") }}</p>
    <p>{{ $t("deposit.external.info.bic") }}</p>
    <br />
    <p>{{ $t("deposit.external.info.reason_for_payment") }} {{ value }}</p>
  </div>
</template>
<script>
export default {
  name: "DepositExternalInfoBox",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
};
</script>
