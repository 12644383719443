<template>
  <div>
    <p style="font-weight: bold">
      {{
        $t("deposit.info.deposit_units.taking", [
          value.taking.name,
          value.taking.event.type_of_event,
        ])
      }}
    </p>
    <vca-row>
      <p>{{ $t("deposit.info.deposit_units.donation") }}</p>
      <p>{{ formatMoney(getDonationValue()) }}</p>
    </vca-row>
    <vca-row>
      <p>{{ $t("deposit.info.deposit_units.economic") }}</p>
      <p>{{ formatMoney(getEconomicValue()) }}</p>
    </vca-row>
    <hr style="width: 50%" align="left" />
    <vca-row :class="colError">
      <p>{{ $t("deposit.info.deposit_units.sum") }}</p>
      <p>{{ formatMoney(getAllValue()) }}</p>
    </vca-row>
    <vca-row :class="colError">
      <p>{{ $t("deposit.info.deposit_units.taking_sum") }}</p>
      <p>{{ formatMoney(value.money) }}</p>
    </vca-row>
    <br /><br />
  </div>
</template>
<script>
export default {
  name: "DepositUnitInfo",
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          taking: {},
        };
      },
    },
  },
  methods: {
    getDonationValue() {
      let amount = 0;
      this.value.taking.sources.forEach((e) => {
        if (e.norms === "donation") {
          amount += e.money.amount;
        }
      });
      return { amount: amount, currency: "EUR" };
    },
    getEconomicValue() {
      let amount = 0;
      this.value.taking.sources.forEach((e) => {
        if (e.norms === "economic") {
          amount += e.money.amount;
        }
      });
      return { amount: amount, currency: "EUR" };
    },
    getAllValue() {
      let amount = 0;
      this.value.taking.sources.forEach((e) => {
        amount += e.money.amount;
      });
      return { amount: amount, currency: "EUR" };
    },
  },
  computed: {
    colError() {
      if (this.value.money.amount !== this.getAllValue().amount) {
        return "col-error";
      }
      return "";
    },
  },
};
</script>
<style lang="scss">
.col-error {
  background-color: $red;
  color: black;
}
</style>
