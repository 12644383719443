<template>
  <vca-column>
    <vca-card v-if="!edit_view" class="shadowed">
      <DepositInfo v-model="current" />
      <button class="vca-button" style="width: auto" @click="start_edit()">
        {{ $t("button.edit") }}
      </button>
    </vca-card>
    <vca-card v-if="edit_view" class="shadowed">
      <vca-field :label="$t('deposit.add.info')">
        <h3>{{ $t("deposit.add.crew") }}</h3>
        <div v-if="!hasSystemPermission()">
          {{ user.crew.name }}
        </div>
        <div v-else>
          <vca-dropdown
            ref="crew"
            v-model="crewsList"
            :options="crewList"
            :title="$t('taking.form.crew.title')"
            :rules="$v.edit.crew_id"
            label=""
            :errorMsg="$t('taking.form.crew.errorMsg')"
            :placeholder="$t('taking.form.crew.placeholder')"
          />
        </div>
      </vca-field>
      <vca-field :label="$t('deposit.add.takings')">
        <p>
          {{ $t("deposit.add.description") }}
        </p>
        <br />
        <vca-row
          class="deposit-lists"
          :key="index"
          v-for="(res, index) in edit.deposit_units"
        >
          <IconButton
            type="cancel"
            @click="remove(index)"
            :title="$t('button.remove')"
          />
          <div class="vertical-center">{{ res.taking.name }}</div>
          <div class="margin-auto">
            <vca-money-input
              v-model="res.money"
              ref="amount"
              :currency="currencies"
              :select="true"
              css="small-money-input"
              :errorMsg="$t('taking.form.source.amount.errorMsg')"
              :toptext="$t('taking.form.source.amount.topText')"
            />
          </div>
          <div></div>
        </vca-row>
      </vca-field>
      <vca-field :label="$t('deposit.external.header')">
        <vca-checkbox v-model="edit.has_external">
          {{ $t("deposit.add.has_external") }}</vca-checkbox
        >
        <DepositExternal
          v-if="edit.has_external"
          v-model="edit.external"
          :reason_for_payment="edit.reason_for_payment"
        />
        <DepositExternalInfoBox v-model="edit.reason_for_payment" />
      </vca-field>
      <vca-field v-if="edit.has_external" :label="$t('deposit.receipt.header')">
        <ReceiptDisplay v-model="edit.receipts" @updated="reload">
        </ReceiptDisplay>
        <ReceiptUpload :id="edit.id" @updated="reload"></ReceiptUpload>
      </vca-field>
      <div class="vca-center">
        <div style="display: inline-flex; gap: 2em">
          <div>
            <button
              class="vca-button"
              style="width: auto"
              @click.prevent="update()"
            >
              {{ $t("button.save") }}
            </button>
          </div>
          <div>
            <button
              class="vca-button"
              style="background-color: #f52; width: auto"
              @click="reset_edit()"
            >
              {{ $t("button.clear") }}
            </button>
          </div>
        </div>
      </div>
    </vca-card>
    <vca-card v-if="edit_view" class="shadowed">
      <DepositAddTable v-model="edit" />
    </vca-card>
  </vca-column>
</template>
<script>
import { mapGetters } from "vuex";
import DepositAddTable from "./DepositAddTable.vue";
import DepositInfo from "./DepositInfo.vue";
import IconButton from "@/components/utils/IconButton";
import DepositExternal from "./DepositExternal.vue";
import ReceiptUpload from "./ReceiptUpload.vue";
import ReceiptDisplay from "./ReceiptDisplay.vue";
import DepositExternalInfoBox from "./info/DepositExternalInfoBox.vue";
export default {
  name: "DepositEdit",
  components: {
    DepositAddTable,
    DepositInfo,
    IconButton,
    DepositExternal,
    ReceiptUpload,
    ReceiptDisplay,
    DepositExternalInfoBox,
  },
  data() {
    return {
      edit_view: false,
    };
  },
  methods: {
    start_edit() {
      this.edit_view = true;
      this.$store.commit("deposits/start_edit");
    },
    reset_edit() {
      this.edit_view = false;
      this.$store.commit("deposits/reset_edit");
    },
    reload() {
      this.$store
        .dispatch({
          type: "deposits/getByID",
          data: this.edit.id,
        })
        .then(() => {
          this.$store.commit("deposits/start_edit");
        });
    },
    remove(index) {
      this.edit.deposit_units.splice(index, 1);
    },
    updateTakingsList() {
      let state = { status_open: true, crew_id: this.edit.crew_id };
      this.$store.commit("takings/query", state);
      this.$store.dispatch({ type: "takings/list" });
    },
    resetDepositUnits() {
      this.edit.deposit_units = [];
    },
    update() {
      if (this.$v.edit.$invalid) {
        this.$refs.crew.validate();
        return false;
      }
      this.$store
        .dispatch({ type: "deposits/update" })
        .then(() => {
          this.edit_view = false;
          this.$store.commit("deposits/reset_edit");
          this.$store.dispatch({ type: "takings/list" });
        })
        .catch(() => {
          this.reset_edit();
        });
    },
  },
  validations() {
    return this.validation;
  },
  computed: {
    crewName() {
      return this.value.crew ? this.value.crew.name : "-";
    },
    ...mapGetters({
      user: "user/current",
      crewList: "crews/dropdown",
      validation: "deposits/validations",
      hasSystemPermission: "user/roles/hasSystemPermission",
      current: "deposits/current",
      currencies: "currencies",
    }),
    crewsList: {
      set(value) {
        if (value.length > 0) {
          if (this.edit.crew_id != value[0].value) {
            this.edit.crew_id = value[0].value;
            this.updateTakingsList();
            this.resetDepositUnits();
          }
        } else {
          this.edit.crew_id = null;
        }
      },
      get() {
        return !this.edit.crew_id ? [] : [{ value: this.edit.crew_id }];
      },
    },
    edit: {
      set(value) {
        this.$store.commit("deposits/edit", value);
      },
      get() {
        return this.$store.state.deposits.edit;
      },
    },
  },
};
</script>
